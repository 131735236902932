import { graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import React from "react"
import Seo from "../components/seo"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"

deckDeckGoHighlightElement()

const SinglePost = ({ data }) => {
  const post = data.markdownRemark.frontmatter
  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.blurb}
        ogImage={post.image.childImageSharp.fluid.src}
        isArticle={true}
        twitterUsername={post.authorTwitterUsername}
      />
      <div className="container blog-post-container">
        <h1>{post.title}</h1>
        <div className="post-header">
          <div className="post-header--details">
            <div className="avatar-wrapper">
              <Img fluid={post.authorImage.childImageSharp.fluid} />
            </div>
            <div className="text-info--wrapper">
              <span className="text-info">{post.author}</span>
              <span className="text-info">{post.date}</span>
            </div>
          </div>
        </div>
        <div className="image-wrapper">
          <Img fluid={post.image.childImageSharp.fluid} />
        </div>
        <div
          className="post-body"
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></div>
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query blogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        blurb
        author
        authorImage {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        authorTwitterUsername
        date(formatString: "MMM Do YYYY")
        image {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default SinglePost
